.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-top: 40px;
}

@media screen and (min-width: 940px) {
  .profile {
    flex-direction: row;
    width: 880px;
    gap: 1.88rem;
  }
}
