.overlay__text-input {
  width: 100%;
  height: 54px;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  outline: none;
  border-bottom: 1px solid #0000002e;
}

@media screen and (max-width: 1279px) {
  .overlay__text-input {
    height: 44px;
  }
}
