.content__profile-button-edit {
  width: 1.125rem;
  height: 1.125rem;
  background-color: transparent;
  background-image: url(../../../../images/edit-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  border: 1px solid #ffffff;
  cursor: pointer;
}

.content__profile-button-edit:hover {
  opacity: 60%;
}
