.header__line {
  width: 20rem;
  opacity: 0.7;
  border: 1px solid #545454;
}

@media screen and (min-width: 940px) {
  .header__line {
    width: 55rem;
  }
}
