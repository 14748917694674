.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.75rem;
}

/* @media screen and (min-width: 940px) {
  .header {
    width: 880px;
    margin: 0 auto;
  }
} */
