.footer__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #545454;
  margin: 0;
  width: 282px;
}

@media screen and (min-width: 1280px) {
  .footer__text {
    width: 880px;
  }
}
