@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("../../fonts/Inter-ExtraBold.woff") format("woff");
  font-weight: 900;
}

.root {
  background-color: #000000;
}
