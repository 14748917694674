.overlay__content-preview {
  width: auto;
  height: auto;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
}

@media screen and (max-width: 1279px) {
  .overlay__content-preview {
    padding: 0;
    border-radius: 0;
  }
}
