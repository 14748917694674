.content__elements__numbers-like {
  font-family: "Inter", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  padding-top: 3px;
}
